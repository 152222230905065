<template>
  <div class="active-code-card">
    <div class="card-image" :style="backgroundStyle"></div>
    <div class="card-mobile-image" :style="mobileBackgroundStyle"></div>
    <div class="card-body">
      <div class="code-description">
        <span class="code-status">{{ $t("active") }}</span>
        <template>
          <span v-if="seasonPass">{{
            code.pass_description || code.pass_name
          }}</span>
          <span v-else>{{ code.pass_code }}</span>
        </template>
      </div>
      <div class="card-button">
        <mem-button v-if="seasonPass" btn-type="tertiary-dark" disabled>
          <span>{{ $t("auto_applied") }}</span>
        </mem-button>
        <mem-button
          class="button-copy"
          v-else
          btn-type="tertiary-dark"
          @click="copyCode"
        >
          <span class="material-icons copy-icon"> content_copy </span>
          <span v-if="!linkCopied">{{ $t("copy_code") }}</span>
          <span v-if="linkCopied">{{ $t("copied") }}</span>
        </mem-button>
        <div class="mobile-modal-button" @click.stop="modal = true">
          <span class="button-title">{{ $t("my_codes_how_it_works") }}</span>
          <span class="material-icons-outlined info-icon"> info </span>
        </div>
      </div>
      <div class="code-info">
        <div class="code-type">
          <span class="info-subtitle">{{ $t("type") }}</span>
          <span>{{ code.pass_name }}</span>
        </div>
        <div class="code-date">
          <span class="info-subtitle">{{ $t("expires") }}</span>
          <span>{{ expiresDate }}</span>
        </div>
      </div>
      <div class="modal-button" @click.stop="modal = true">
        <span class="material-icons-outlined info-icon"> info </span>
      </div>

      <v-dialog v-model="modal" max-width="268">
        <div class="code-modal">
          <div class="close-button" @click.stop="modal = false">
            <span class="material-icons close-icon"> close </span>
          </div>
          <!-- <span class="modal-title"> {{ code.pass_name }} </span> -->
          <div class="modal-text" v-html="modalText"></div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { marked } from "marked";

export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  props: ["code"],
  data: () => ({
    linkCopied: false,
    modal: false,
  }),
  computed: {
    backgroundStyle() {
      if (this.seasonPass)
        return {
          backgroundImage: `url(${require("@/assets/code_card_bg_season.png")})`,
        };
      return {
        backgroundImage: `url(${require("@/assets/code_card_bg_trifecta.png")})`,
      };
    },
    mobileBackgroundStyle() {
      if (this.seasonPass)
        return {
          backgroundImage: `url(${require("@/assets/code_card_mobile_bg_season.png")})`,
        };
      return {
        backgroundImage: `url(${require("@/assets/code_card_mobile_bg_trifecta.png")})`,
      };
    },
    seasonPass() {
      return this.code.pass_identifier === "season";
    },
    expiresDate() {
      return moment.utc(this.code.expires).format("MMM DD, YYYY");
    },
    modalText() {
      return marked.parse(this.code?.pass_disclaimer?.text || "");
    },
  },
  methods: {
    async copyCode() {
      await navigator.clipboard.writeText(this.code.pass_code);

      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
.active-code-card {
  display: grid;
  grid-template-columns: max-content 1fr;
  min-height: 160px;
  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 216px;
    background-color: #191818;
  }
  .card-image {
    width: 25px;
    border-radius: 8px 0 0 8px;
    background-size: cover;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
  .card-mobile-image {
    display: none;
    background-size: cover;
    @media screen and (max-width: $mobile) {
      display: inline-block;
      width: 100%;
      height: 8px;
      border-radius: 8px 8px 0 0;
    }
  }
  .card-body {
    position: relative;
    padding: 24px 46px 24px 24px;
    border-radius: 0 8px 8px 0;
    border-top: 1px solid #2e2c2c;
    border-right: 1px solid #2e2c2c;
    border-bottom: 1px solid #2e2c2c;

    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr auto;
    @media screen and (max-width: $mobile) {
      flex-grow: 1;
      padding: 24px 16px;
      border-radius: 0 0 8px 8px;
      border-top: none;
      border-left: 1px solid #2e2c2c;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
    }

    .code-description {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.04px;
      text-transform: capitalize;
      .code-status {
        color: #75b138;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px; /* 90.909% */
        text-transform: uppercase;
        margin-bottom: 4px;
      }
    }
    .card-button {
      @media screen and (max-width: $mobile) {
        order: 2;
        display: grid;
        grid-template-columns: 1fr;
        // justify-self: center;
      }
      .button-copy {
        min-width: 174px;
      }
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .copy-icon {
          font-size: 20px;
          margin-right: 10px;
        }
      }
      .mobile-modal-button {
        display: none;
        padding-top: 12px;
        @media screen and (max-width: $mobile) {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .button-title {
          color: #aaa;
          margin-right: 12px;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.48px;
          text-transform: uppercase;
        }
        .info-icon {
          font-size: 20px;
          color: #aaa;
        }
      }
    }
    .code-info {
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-column: span 2;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.035px;
      @media screen and (max-width: $mobile) {
        margin: 16px 0;
        grid-column: auto;
      }
      .info-subtitle {
        color: #9d9d9d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.03px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      .code-type {
        display: flex;
        flex-direction: column;
        margin-right: 6px;
        overflow: hidden;
        @media screen and (max-width: $mobile) {
          overflow: visible;
        }
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media screen and (max-width: $mobile) {
            overflow: visible;
            white-space: normal;
          }
        }
      }
      .code-date {
        display: flex;
        flex-direction: column;
        text-align: end;
        @media screen and (max-width: $mobile) {
          text-align: start;
        }
      }
    }
    .modal-button {
      position: absolute;
      right: 12px;
      top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      z-index: $zindex-page-element;
      @include cursorPointer;
      @media screen and (max-width: $mobile) {
        display: none;
      }
      .info-icon {
        font-size: 22px;
        color: #aaa;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}
.active-code-card + .active-code-card {
  margin-top: 48px;
  @media screen and (max-width: $mobile) {
    margin-top: 24px;
  }
}
.code-modal {
  padding: 36px 24px 16px 24px;
  position: relative;
  background-color: #191818;
  display: flex;
  flex-direction: column;
  .modal-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 114.6%;
    letter-spacing: 0.05px;
    text-transform: capitalize;
    // margin-bottom: 12px;
  }
  .modal-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 124.4%;
  }
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include cursorPointer;
    .close-icon {
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>